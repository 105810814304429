import React, { FC } from 'react';
// Model
import { formSchemaCambiarContrasena, initialStateCambiarContrasena } from '../../core/models/usuarios/authModel';
// Reactstrap
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  ModalBody,
} from 'reactstrap';
//FORM
import { Formik, Form, Field } from 'formik';
import ModalsFooter from './ModalsFooter';
import { useTranslation } from 'react-i18next';

const CambiarContrasena: FC<any> = ({ onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={initialStateCambiarContrasena}
      validationSchema={formSchemaCambiarContrasena}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => {
        return (
          <>
            <ModalBody>
              <Form id="formContrasena">
                <Row>
                  <Col sm={12}>
                    <FormGroup>
                      <Label>{t('Contraseña actual')}</Label>
                      <Field
                        as={Input}
                        name="actual"
                        type="password"
                        placeholder={t('Ingrese la contraseña actual')}
                        invalid={errors.actual && touched.actual}
                      />
                      <FormFeedback>{errors.actual && t(errors.actual)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup>
                      <Label>{t('Nueva contraseña')}</Label>
                      <Field
                        as={Input}
                        name="password"
                        type="password"
                        placeholder={t('Ingrese la nueva contraseña')}
                        invalid={errors.password && touched.password}
                      />
                      <FormFeedback>{errors.password && t(errors.password)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup>
                      <Label>{t('Repita la contraseña')}</Label>
                      <Field
                        as={Input}
                        name="password_repeat"
                        type="password"
                        placeholder={t('Repita la nueva contraseña')}
                        invalid={errors.password_repeat && touched.password_repeat}
                      />
                      <FormFeedback>{errors.password_repeat && t(errors.password_repeat)}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalsFooter
              idForm="formContrasena"
            />
          </>
        )
      }}
    </Formik>
  );
};
export default CambiarContrasena;
