import { contractualTypeActions } from '..';
import { IContractual } from '../../../core/models/contractual/contractualModel';
import { IContractualActions } from '../interfaces/contractualInterfaces';

export function updateProceso(payload: IContractual): IContractualActions {
  return {
    type: contractualTypeActions.PROCESO,
    payload
  };
};

export function updateId(payload: string): IContractualActions {
  return {
    type: contractualTypeActions.ID,
    payload
  };
};

export function updateContraUsuario(payload: any[]): IContractualActions {
  return {
    type: contractualTypeActions.POST_USER,
    payload
  };
};

export function updateRequerimientos(payload: any[]): IContractualActions {
  return {
    type: contractualTypeActions.REQUERIMIENTOS,
    payload
  };
};

export function updateUsuarios(payload: any[]): IContractualActions {
  return {
    type: contractualTypeActions.USUARIOS,
    payload
  };
};

export function updateTareas(payload: any[]): IContractualActions {
  return {
    type: contractualTypeActions.TAREAS,
    payload
  };
};

export function updateDocumentos(payload: any[]): IContractualActions {
  return {
    type: contractualTypeActions.DOCUMENTOS,
    payload
  };
};

export function updateRoles(payload: any[]): IContractualActions {
  return {
    type: contractualTypeActions.ROLES,
    payload
  };
};

export function updateAprobaciones(payload: any[]): IContractualActions {
  return {
    type: contractualTypeActions.APROBACIONES,
    payload
  };
};

export function updateState(payload: any): IContractualActions {
  return {
    type: contractualTypeActions.STATE,
    payload
  };
};
