import React, {FC, useEffect, useState} from 'react';
import {dashboardTareas} from '../../../core/services/procesos/tareasService';
import {ITareas, ITotalesTareas} from '../../../core/models/procesos/tareasModel';
import {Spinner} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import TareasCliente from './TareasCliente';
import TareasPredeterminadas from './TareasPredeterminadas';
import PendientesNegocios from './PendientesNegocios';
import {IPostcontractual} from '../../../core/models/postcontractual/postcontractualModel';
import {useUserContext} from '../../../shared/contexts/userContext';
import {EGroupsNames} from '../../../shared/utilities';
import {debounce} from 'lodash';

const TareasDashboard: FC = () => {
  const {t} = useTranslation();
  const {userState} = useUserContext();

  const [loading, setLoading] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [tareasCliente, setTareasCliente] = useState<ITareas[]>([]);
  const [tareasPredeterminadas, setTareasPredeterminadas] = useState<ITareas[]>([]);
  const [negocios, setNegocios] = useState<IPostcontractual[]>([]);
  const [totales, setTotales] = useState<ITotalesTareas>({
    cliente: 0,
    predeterminadas: 0,
    negocios: 0,
  })
  const [search, setSearch] = useState({
    cliente: '',
    predeterminadas: '',
    negocios: '',
  });

  const debouncedSearch = debounce((callback: () => void) => {
    callback();
  }, 500);

  const listarDashboardTareas = async () => {
    const records = await dashboardTareas();
    setTareasCliente(records.cliente);
    setTareasPredeterminadas(records.predeterminadas);
    setNegocios(records.negocios);
    setTotales(records.total);
    setLoading(false);
  }

  const onChangePageTareasCliente = async (page: number) => {
    setTableLoader(true);
    const params = {
      tipo: 'cliente',
      page: page,
      search: search.cliente,
    };
    const records = await dashboardTareas(params);
    setTareasCliente(records.cliente);
    setTableLoader(false);
  }

  const onSearchTareasCliente = async (searchText: string) => {
    debouncedSearch(async () => {
      setTableLoader(true);
      const params = {
        tipo: 'cliente',
        search: searchText,
      };
      const records = await dashboardTareas(params);
      setTareasCliente(records.cliente);
      setSearch({
        ...search,
        cliente: searchText,
      });
      setTotales({
        ...totales,
        cliente: records.total.cliente,
      });
      setTableLoader(false);
    });
  }

  const onChangePageTareasPredeterminadas = async (page: number) => {
    setTableLoader(true);
    const params = {
      tipo: 'predeterminadas',
      page: page,
      search: search.predeterminadas,
    };
    const records = await dashboardTareas(params);
    setTareasPredeterminadas(records.predeterminadas);
    setTableLoader(false);
  }

  const onSearchTareasPredeterminadas = async (searchText: string) => {
    debouncedSearch(async () => {
      setTableLoader(true);
      const params = {
        tipo: 'predeterminadas',
        search: searchText,
      };
      const records = await dashboardTareas(params);
      setTareasPredeterminadas(records.predeterminadas);
      setSearch({
        ...search,
        predeterminadas: searchText,
      });
      setTotales({
        ...totales,
        predeterminadas: records.total.predeterminadas,
      });
      setTableLoader(false);
    });
  }

  const onChangePageNegocios = async (page: number) => {
    setTableLoader(true);
    const params = {
      tipo: 'negocios',
      page: page,
      search: search.negocios,
    };
    const records = await dashboardTareas(params);
    setNegocios(records.negocios);
    setTableLoader(false);
  }

  const onSearchNegocios = async (searchText: string) => {
    debouncedSearch(async () => {
      setTableLoader(true);
      const params = {
        tipo: 'negocios',
        search: searchText,
      };
      const records = await dashboardTareas(params);
      setNegocios(records.negocios);
      setSearch({
        ...search,
        negocios: searchText,
      });
      setTotales({
        ...totales,
        negocios: records.total.negocios,
      });
      setTableLoader(false);
    });
  }

  useEffect(() => {
    listarDashboardTareas();
  }, []);

  const usuarioTieneRoles = (roles: string[]) => {
    return !!userState.data?.roles.find(rol1 => roles.find(rol2 => rol2 === rol1));
  }

  const verTareasCliente = usuarioTieneRoles([
    EGroupsNames.FORMALIZADOR_GESTION_TAREAS,
    EGroupsNames.CUMPLIMIENTO_GESTION,
    EGroupsNames.COORDINADOR,
    EGroupsNames.SUPER_USUARIO,
  ]);

  const verTareasPredeterminadas = usuarioTieneRoles([
    EGroupsNames.FORMALIZADOR_ASEGURAMIENTO_Y_CONTROL,
    EGroupsNames.CUMPLIMIENTO_GESTION,
    EGroupsNames.COORDINADOR,
    EGroupsNames.SUPER_USUARIO,
    EGroupsNames.CUMPLIMIENTO_ASEGURAMIENTOY_CONTROL,
  ]);

  return (
    <div className="mb-4">
      {loading ? (
        <div className="d-flex flex-column justify-content-center text-center">
          <Spinner color="primary" className="m-auto"/>
          <span className="mt-3">
            {t('Cargando tareas...')}
          </span>
        </div>
      ) : (
        <>
          <div className="mb-4" style={{position: 'relative'}}>
            {tableLoader && (<div className="table-loader-overlay"></div>)}
            <TareasCliente
              tareas={tareasCliente}
              onChangePage={onChangePageTareasCliente}
              onSearchChange={onSearchTareasCliente}
              total={totales.cliente}
            />
          </div>
          <div className="mb-4" style={{position: 'relative'}}>
            {tableLoader && (<div className="table-loader-overlay"></div>)}
            <TareasPredeterminadas
              tareas={tareasPredeterminadas}
              onChangePage={onChangePageTareasPredeterminadas}
              onSearchChange={onSearchTareasPredeterminadas}
              total={totales.predeterminadas}
            />
          </div>
          <div className="mb-4" style={{position: 'relative'}}>
            {tableLoader && (<div className="table-loader-overlay"></div>)}
            <PendientesNegocios
              records={negocios}
              onChangePage={onChangePageNegocios}
              onSearchChange={onSearchNegocios}
              total={totales.negocios}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default TareasDashboard;
