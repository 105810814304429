import React, { FC } from "react";
import classNames from "classnames";

const Header: FC<IHeaders> = ({ children, className, tag: Tag, ...rest }) => (
  <Tag className={classNames("header", className)} {...rest}>
    {children}
  </Tag>
);
export interface IHeaders {
  className?: string;
  tag?: any;
};
Header.defaultProps = {
  tag: "div"
};
export default Header;
