import { mainTypeActions } from '..';
import { IMainActions, IMainReducer } from "../interfaces/mainInterfaces";

export function changeHeader(payload: IMainReducer): IMainActions {
  return {
    type: mainTypeActions.HEADER,
    payload: payload
  };
};

export function openModal(payload: IMainReducer): IMainActions {
  return {
    type: mainTypeActions.OPEN_MODAL,
    payload: payload
  };
};

export function closeModal(): IMainActions {
  return {
    type: mainTypeActions.CLOSE_MODAL
  };
};

export function changeLoading(): IMainActions {
  return {
    type: mainTypeActions.CHANGE_LOADING
  };
};

export function trueLoading(): IMainActions {
  return {
    type: mainTypeActions.TRUE_LOADING
  };
};

export function falseLoading(): IMainActions {
  return {
    type: mainTypeActions.FALSE_LOADING
  };
};

export function changeLanguage(payload: IMainReducer): IMainActions {
  return {
    type: mainTypeActions.CHANGE_LANGUAGE,
    payload: payload
  };
};
