import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Button, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label, Row, Col } from 'reactstrap';
import { changeLoading } from '../contexts/actions/mainActions';
import { useMainContext } from '../contexts/mainContext';
import { useUserContext } from '../contexts/userContext';
import { useTranslation } from 'react-i18next';
import { useTareasContext } from '../contexts/tareasContext';
import { Field, Formik, Form, FormikProvider, useFormik } from 'formik';
import { crearComentario, editarComentario, obtenerComentarios } from '../../core/services/procesos/tareasService';
import * as Yup from 'yup';
import { ECodeFabrica, FormValidation } from '../utilities';
import { IUsuarios } from '../../core/models/usuarios/usuariosModel';
import CheckboxField from './CheckboxField';
import { usePostcontractualContext } from '../contexts/postcontractualContext';

const TareasComentarios: FC<ITareasComentarios> = ({ disabled, idUserComenta }) => {
  const [comentarios, setComentarios] = useState<any[]>([]);
  const { handleNotification, mainDispatch, mainState } = useMainContext();
  const { userState } = useUserContext();
  const { t } = useTranslation();
  const { tarea } = useTareasContext();
  const { postRoles } = usePostcontractualContext();

  const initialValues = {
    title: "",
    body: "",
  };

  const habilitarAdjuntos = useMemo(() => {

    //Habilitación comentarios y archivos para estado Aprobación Cumplimiento:
    //Tarea cliente | usuario cumplimiento gestion
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_CLI_APROBACION_CUMPLIMIENTO && !!postRoles?.cumplimiento_gestion && tarea?.tipo === "tareacliente") return true;
    //Tarea predeterminada | elaborar | usuario cumplimiento aseguramiento y control
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_PRE_APROBACION_CUMPLIMIENTO &&  !!postRoles?.cumplimiento_aseguramiento_y_control && tarea?.clasificacionoperativa === "elaborar") return true;
  
    //Habilitación comentarios y archivos para estado En proceso:
    //Tarea cliente | usuario cumplimiento gestion
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_CLI_EN_PROCESO && !!postRoles?.cumplimiento_gestion && tarea?.tipo === "tareacliente") return true;
    //Tarea predeterminada | elaborar | usuario formalizador aseguramiento
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_PRE_EN_PROCESO && !!postRoles?.formalizador_aseguramiento_y_control && tarea?.clasificacionoperativa === "elaborar") return true;
    //****Tarea predeterminada solicitar no se debe habilitar En el estado en proceso por lo tanto no se ponen más habilitadores 

    //Habilitación comentarios y archivos para estado Revisión Formalizador:
    //Tarea predeterminada | solicitar | formalizador aseguramiento
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_PRE_REVISION_FORMALIZADOR && !!postRoles?.formalizador_aseguramiento_y_control && tarea?.clasificacionoperativa === "solicitar_informacion_cliente") return true;
    //Tarea predeterminada | solicitar | usuario creador
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_PRE_REVISION_FORMALIZADOR && (tarea?.usuariocreador as IUsuarios)?.id === userState?.data?.sub && tarea?.clasificacionoperativa === "solicitar_informacion_cliente") return true;
    
    return false;
  }, [tarea, userState, comentarios, postRoles]);

  const verAdjuntos = useMemo(()=>{
    if(Number(tarea?.idestados?.codigo) === 11) return true;
    if(Number(tarea?.idestados?.codigo) === 12) return true;
    if(Number(tarea?.idestados?.codigo) === 13) return true;
    if(Number(tarea?.idestados?.codigo) === 15) return true;
    if(Number(tarea?.idestados?.codigo) === 4) return true;
    if(Number(tarea?.idestados?.codigo) === 17) return true;
    if(Number(tarea?.idestados?.codigo) === 5) return true;
    return false;
  },[tarea])

  const listarComentarios = async () => {
    const params = {
      filter: 'idtareas',
      valuefilter: tarea?.id
    };
    setComentarios(await obtenerComentarios(params) || []);
  };

  const saveComment = async (values: any) => {
    mainDispatch(changeLoading());
    const dataSend = {
      ...values,
      idusuarios: idUserComenta || userState?.data?.sub || null,
      idtareas: tarea?.id
    };
    if (await crearComentario(dataSend, handleNotification)) {
      await listarComentarios();
    }
    mainDispatch(changeLoading());
  };

  useEffect(() => {
    if (tarea) {
      listarComentarios();
    }
  }, [tarea]);

  const enableAtachmentCom = async (idcom: Number)=>{

    const adjunto = comentarios.find((item)=>(item.id == idcom)).adjuntocorreo;
  
    const values = {
      id: idcom,
      idtareas: tarea?.id,
      adjuntocorreo: !adjunto,
    }
  
    comentarios.map((item)=>{
      if(item.id == idcom){
        item.adjuntocorreo = !adjunto
      }
    })

    if(await editarComentario(values,handleNotification)){
      await listarComentarios();
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
  });

  return (
    <>
      {tarea?.idestados.codigofabrica === ECodeFabrica.TR_CLI_REVISION_AREAS ?
        <h5>{t('Respuesta a la consulta')}</h5>
        :
        <h5>{t('Comentarios')}</h5>
      }
      <hr />
      <Formik
        enableReinitialize
        initialValues={{
          comentario: ''
        }}
        validationSchema={Yup.object().shape({
          comentario: Yup.string().required(FormValidation.required),
        })}
        onSubmit={saveComment}
      >
        {({ errors, touched, isSubmitting }) => {
          return (
            <>
              <Form>
                <FormGroup>
                  <InputGroup>
                    <Field
                      as={Input}
                      type="textarea"
                      name="comentario"
                      invalid={errors.comentario && touched.comentario}
                      className="form-control"
                      disabled={disabled}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={disabled || isSubmitting || mainState.loading}
                      >{t('Enviar')}</Button>
                    </InputGroupAddon>
                    <FormFeedback>{(errors.comentario && touched.comentario) && t(errors.comentario)}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Form>
            </>
          )
        }}
      </Formik>
      <div className="chat-messages">
        {
          <div>
            {(comentarios?.length > 0) ? comentarios?.map(({ comentario, creado, idusuarios, id, adjuntocorreo }) => (
              <Fragment key={id}>
                <span style={{
                  fontSize: 10,
                  textAlign: 'left'
                }}>
                  {idusuarios ?
                    `${(idusuarios as IUsuarios).nombres} ${(idusuarios as IUsuarios).apellidos} | ${creado}` :
                    `Fideicomitente | ${creado}`
                  }
                </span>
                <Row>
                  <Col md={10}>
                    <div className="flex-shrink-5 bg-dark rounded py-2 px-3 my-2 "> 
                        <p>          
                          {comentario}
                        </p> 
                    </div>
                  </Col>
                  { verAdjuntos &&
                  <Col md={2}>
                    <FormikProvider value={formik}>
                      <Label className='mb-0'>{t('Correo')}</Label>
                      <Field
                        as={CheckboxField}
                        id={id}
                        name={'idfor'+id}
                        onClick={()=>{enableAtachmentCom(id)}}
                        checked={adjuntocorreo}
                        disabled={!habilitarAdjuntos}
                      />
                    </FormikProvider>
                  </Col>
                  }
                </Row>
                </Fragment>
              
            )) : (
              <div>
                <span>{t("No tiene comentarios")}</span>
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
};
interface ITareasComentarios {
  disabled?: boolean;
  idUserComenta?: number | null;
};
export default TareasComentarios;
