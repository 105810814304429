import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mainContext, userContext } from '../../shared/contexts';
import { changeHeader } from '../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../shared/contexts/interfaces/mainInterfaces';
import { notificationContext } from '../../shared/contexts/notificationContext';
import { mainInitialState } from '../../shared/contexts/reducers/mainReducer';
// Componentes
import HomeComponent from './Home.component';

const Home: FC = () => {
  const { t } = useTranslation();
  const { mainDispatch } = useContext(mainContext);
  const { userState } = useContext(userContext);
  const { notificationState } = useContext(notificationContext);

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: `${t('Hola')}, ${userState.data?.firstname} ${userState.data?.lastname}`,
        subtitle: `${t('Tienes')} ${notificationState.notifications?.length} ${t('notificaciones por leer')}.`,
        type: mainTypeHeader.SUBTITLE
      }
    }));
    // eslint-disable-next-line
  }, [notificationState])

  return (
    <HomeComponent />
  )
}
export default Home;
