import { IItemsLista } from "../../core/models/administracion/listasItauModels";
import { useMainContext } from "../contexts/mainContext";

export const useAllListas = () => {
  // State
  const { listasDesplegables } = useMainContext();

  const obtenerLista = (numerolista: number): IItemsLista[] => {
    const lista = listasDesplegables.find(item => item.numerolista === numerolista);
    return lista?.itemslista || [];
  }

  const obtenerSublista = (numerolistapadre: number, opcionpadre: string | null): IItemsLista[] => {
    if (!opcionpadre) return [];
    const listaPadre = listasDesplegables.find(item => item.numerolista === numerolistapadre);
    const itemPadre = listaPadre?.itemslista.find(item => item.itemlista === opcionpadre);
    const lista = listasDesplegables.find(item => item.itemlistapadre?.id === itemPadre?.id)
    return lista?.itemslista || [];
  }

  const obtenerSublistaNombre = (nombrelistapadre: string): IItemsLista[] => {
    const listaPadre = listasDesplegables.find(item => item.itemlistapadre?.itemlista === nombrelistapadre);
    return listaPadre?.itemslista || [];
  }

  const obtenerLabel = (codigo: string | null): string | null => {
    let nombre: string | null = null;
    listasDesplegables.map(({ itemslista }) => {
      itemslista.map(({ itemlista, nombreitem }) => {
        if (codigo === itemlista) nombre = nombreitem;
      })
    })
    return nombre;
  }

  return {
    listasDesplegables,
    obtenerLista,
    obtenerSublista,
    obtenerSublistaNombre,
    obtenerLabel,
  }
}
