import { postcontractualTypeActions } from '..';
import { IPostcontractual } from '../../../core/models/postcontractual/postcontractualModel';
import { IPostcontractualActions } from '../interfaces/postcontractualInterface';

export function updateProceso(payload: IPostcontractual): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.PROCESO,
    payload
  };
};

export function updateId(payload: string): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.ID,
    payload
  };
};

export function updatePostUsuario(payload: any[]): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.POST_USER,
    payload
  };
};

export function updateRequerimientos(payload: any[]): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.REQUERIMIENTOS,
    payload
  };
};

export function updateUsuarios(payload: any[]): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.USUARIOS,
    payload
  };
};

export function updateTareas(payload: any[]): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.TAREAS,
    payload
  };
};

export function updateDocumentos(payload: any[]): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.DOCUMENTOS,
    payload
  };
};

export function updateRoles(payload: any[]): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.ROLES,
    payload
  };
};

export function updateAprobaciones(payload: any[]): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.APROBACIONES,
    payload
  };
};

export function updateState(payload: any): IPostcontractualActions {
  return {
    type: postcontractualTypeActions.STATE,
    payload
  };
};
