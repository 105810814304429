import { notificationTypeAction } from "..";
import { INotificationActions } from "../interfaces/notificationInterfaces";

export function updateNotifications(payload: any[]): INotificationActions {
  return {
    type: notificationTypeAction.UPDATE_NOTIFICATION,
    payload
  };
}

export function addNotifications(payload: any): INotificationActions {
  return {
    type: notificationTypeAction.ADD_NOTIFICATION,
    payload
  };
}

export function clearNotification(): INotificationActions {
  return {
    type: notificationTypeAction.CLEAR_NOTIFICATIONS
  };
}
