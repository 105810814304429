import React, { createContext, useReducer, useEffect } from 'react';
import notificationReducer, { notificationInitialState } from './reducers/notificationReducer';
import { INotificationContext } from './interfaces/notificationInterfaces';

const initialState: INotificationContext = {
  notificationState: notificationInitialState(),
  notificationDispatch: () => {},
};
export const notificationContext = createContext<INotificationContext>(initialState);

export const NotificationProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(notificationReducer, notificationInitialState());
  return (
    <notificationContext.Provider
      value={{
        notificationState: state,
        notificationDispatch: dispatch,
      }}
    >
      { children }
    </notificationContext.Provider>
  );
};
export const useNotificacionContext = () => {
  const context = React.useContext(notificationContext)
  if (context === undefined) {
    throw new Error('useNotificacionContext must be used within a NotificationProvider');
  }
  return context
};
