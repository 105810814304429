import React, { FC } from "react";
import classNames from "classnames";
import { IHeaders } from "./Header";

const HeaderTitle: FC<IHeaders> = ({ children, className, tag: Tag, ...rest }) => (
  <Tag className={classNames("header-title", className)} {...rest}>
    {children}
  </Tag>
);
HeaderTitle.defaultProps = {
  tag: "h3"
};
export default HeaderTitle;
