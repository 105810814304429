import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { Button } from 'reactstrap';

import { closeModal } from '../contexts/actions/mainActions';

import { INotificaciones } from '../../core/models/notificaciones/notificacionesModel';
import { leerNotificacion, obtenerNotificaciones } from '../../core/services/notificaciones/notificacionesService';
import { useMainContext } from '../contexts/mainContext';
import { useTranslation } from 'react-i18next';
import { useOptionsTables } from '../hooks/useOptionsTables';
import { formatStringtoDateString } from '../helpers/Fecha';

const ListNotificaciones: FC<any> = (history) => {

  // contextos
  const { mainDispatch } = useMainContext();
  const { optionsDataTable } = useOptionsTables();
  const { t } = useTranslation();

  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [notificaciones, setNotificaciones] = useState<INotificaciones[]>([]);

  /**
   * Trae la lista de notificaciones para el usuario
   */
  const listarNotificaciones = async () => {

    const usuarioLocal = localStorage.getItem('usuario');
    const usuarioID = (usuarioLocal) && JSON.parse(usuarioLocal).data.sub;

    setNotificaciones(await obtenerNotificaciones(usuarioID, 3));
    setSkeleton(false);
  };

  useEffect(() => {
    listarNotificaciones();
  }, []);

  const columns: MUIDataTableColumn[] = [
    {
      name: t('Consecutivo'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Consecutivo')}</h5>
        ),
        customBodyRender: (tipo) => {
          let proceso = ''
          switch (tipo.idmodulos) {
            case 1:
              proceso = `/contractual/edit/${tipo.idproceso}`
              break;
            case 2:
              proceso = `/postcontractual/edit/${tipo.idproceso}`
              break;
            case 9:
              proceso = `/asesorias/edit/${tipo.idproceso}`
              break;
            case 10:
              proceso = `/asesorias/edit/${tipo.idproceso}`
              break;
          }
          return (
            <Button
              onClick={() => {
                leerNotificacion(tipo.idseguimiento);
                history.push(`${proceso}`);
                mainDispatch(closeModal());
              }}
            >
              {`${tipo.consecutivo}`}
            </Button>
          );
        }
      }
    },
    {
      name: t('Código'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Código')}</h5>
        ),
      }
    },
    {
      name: t('Mensaje'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Mensaje')}</h5>
        ),
      }
    },
    {
      name: t('Creado'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Creado')}</h5>
        ),
      }
    }
  ];

  return (
    <MUIDataTable
      title={t('Lista de notificaciones')}
      data={notificaciones.map(tipo => ([
        tipo,
        tipo.codigo,
        tipo.mensaje,
        tipo.creado ? formatStringtoDateString(tipo?.creado) : '',
      ]))}
      columns={columns}
      options={optionsDataTable()}
    />
  );
}
interface IListNotificaciones {
  columns: MUIDataTableColumn[],
  notificaciones: INotificaciones[],
};

export default ListNotificaciones;
