import React, { createContext, useReducer, useEffect } from 'react';
import userReducer, { userInitialState } from './reducers/userReducer';
import { IUserContext } from './interfaces/userInterfaces';

const initialState: IUserContext = {
  userState: userInitialState(),
  userDispatch: () => {},
};
export const userContext = createContext<IUserContext>(initialState);

export const UserProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(userReducer, userInitialState());

  useEffect(() => {
    const dataSave = {
      data: state.data,
      isAutenticated: state.isAutenticated,
    }
    localStorage.setItem('usuario', JSON.stringify(dataSave));
  }, [state]);

  return (
    <userContext.Provider
      value={{
        userState: state,
        userDispatch: dispatch,
      }}
    >
      { children }
    </userContext.Provider>
  );
};

export const useUserContext = () => {
  const context = React.useContext(userContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
};
