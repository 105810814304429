import { IUserActions, IUserReducer } from '../interfaces/userInterfaces';
import { notificationTypeAction } from '../index';
import { INotificationActions, INotificationsReducer } from '../interfaces/notificationInterfaces';

// Estado inicial
export const notificationInitialState = (): INotificationsReducer => {
  return {
    notifications: []
  };
};

/**
 * Reducer de usuario
 * @param state estado del reducer
 * @param action acción a ejecutar
 */
const notificationReducer = (state = notificationInitialState(), actions: INotificationActions): INotificationsReducer => {
  switch (actions.type) {
    case notificationTypeAction.ADD_NOTIFICATION:
      return {
        notifications: [
          actions.payload,
          ...state.notifications,
        ]
      };

    case notificationTypeAction.CLEAR_NOTIFICATIONS:
      return {
        notifications: []
      };

    case notificationTypeAction.UPDATE_NOTIFICATION:
      return {
        notifications: (actions.payload) ? actions.payload : []
      };
    default:
      return state;
  }
};
export default notificationReducer;
