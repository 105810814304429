import axios, { AxiosRequestConfig } from 'axios';
import { userLogout } from '../../shared/contexts/actions/userActions';
import { mainTypeMsj } from '../../shared/contexts/interfaces/mainInterfaces';
import { IUserReducer } from '../../shared/contexts/interfaces/userInterfaces';
import { isUserAuthenticated } from '../../shared/helpers/authUtils';
import { getTokenUrl } from '../../shared/utilities';
import dev from '../enviroments';

const AXIOS_DEFAULT_CONFIG: AxiosRequestConfig = {
  validateStatus: (status) => {
    return status === 400 || status === 200 || status === 100
  },
};

export const fileRequest = (url: string, params?: any, auth = true): Promise<any> => {
  let CONFIG: AxiosRequestConfig = {
    ...AXIOS_DEFAULT_CONFIG
  };
  CONFIG.responseType = 'blob';
  if (auth) {
    const user = isUserAuthenticated(true);
    const tokenurl = getTokenUrl();
    if (tokenurl) {
      CONFIG.headers = {
        Authorization: tokenurl
      };
    } else if (user?.isAutenticated) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else if (!user?.isAutenticated && user?.data?.token) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else {
      CONFIG.headers = {
      };
    }
  }
  if (params) {
    CONFIG.params = params;
  }
  return axios.get(`${dev.URL_SERVER}${url}`, CONFIG);
};


export const getRequest = (url: string, params?: any, auth = true): Promise<any> => {
  let CONFIG: AxiosRequestConfig = {
    ...AXIOS_DEFAULT_CONFIG
  };
  if (auth) {
    const user = isUserAuthenticated(true);
    const tokenurl = getTokenUrl();
    if (tokenurl) {
      CONFIG.headers = {
        Authorization: tokenurl
      };
    } else if (user?.isAutenticated) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else if (!user?.isAutenticated && user?.data?.token) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else {
      CONFIG.headers = {
      };
    }
  }
  if (params) {
    CONFIG.params = params;
  }
  return axios.get(`${dev.URL_SERVER}${url}`, CONFIG);
};

export const postRequest = (url: string, body: any, auth = true, third = null): Promise<any> => {
  const CHAIN = JSON.stringify(body);
  const DATA = new FormData();
  DATA.append('json', CHAIN);
  const CONFIG: AxiosRequestConfig = {
    ...AXIOS_DEFAULT_CONFIG,
    headers: {
      'Content-Type': 'application/json'
    },
  }
  if (auth) {
    const user = isUserAuthenticated(true);
    const tokenurl = getTokenUrl();
    if (tokenurl) {
      CONFIG.headers = {
        Authorization: tokenurl
      };
    } else if (user?.isAutenticated) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else if (!user?.isAutenticated && user?.data?.token) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else {
      CONFIG.headers = {
      };
    }
  }
  if (third) {
    CONFIG.headers = {
      Authorization: third
    };
  }
  return axios.post(`${dev.URL_SERVER}${url}`, DATA, CONFIG);
};

export const postRequestJson = (url: string, body: any, auth = true): Promise<any> => {
  const CONFIG: AxiosRequestConfig = {
    ...AXIOS_DEFAULT_CONFIG,
    headers: {
      'Content-Type': 'application/json'
    },
  }
  if (auth) {
    const user = isUserAuthenticated(true);
    const tokenurl = getTokenUrl();
    if (tokenurl) {
      CONFIG.headers = {
        Authorization: tokenurl
      };
    } else if (user?.isAutenticated) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else if (!user?.isAutenticated && user?.data?.token) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else {
      CONFIG.headers = {
      };
    }
  }
  return axios.post(`${dev.URL_SERVER}${url}`, body, CONFIG);
};

export const postRequestForm = (url: string, body: any, auth = true, third = null): Promise<any> => {
  console.log(body);

  const form = new FormData();
  if (body?.archivo) {
    form.append('archivo', body.archivo);
  }

  const archivos = body?.archivos || body?.json?.archivos;
  if (archivos) {
    Object.entries(archivos).map(archivo => {
      const [key] = archivo;
      archivos[key].map((file, i) => {
        form.append(`archivos[${key}][${i}]`, file);
      })
    })
  }

  const anexos = body?.anexos || body?.json?.anexos;
  if (anexos) {
    Object.entries(anexos).map(archivo => {
      const [key] = archivo;
      anexos[key].map((file, i) => {
        form.append(`${key}[${i}]`, file);
      })
    })
  }

  const document = body?.documents || body?.json?.documents;
  if (document) {
    Object.entries(document).map(archivo => {
      const [key] = archivo;
      document[key].map((file, i) => {
        // console.log(file, key)
        form.append(`${key}`, file);
      })
    })
  }
  console.log('crislogb', body)
  const CHAIN = JSON.stringify(body);
  form.append('json', CHAIN);

  const CONFIG: AxiosRequestConfig = {
    ...AXIOS_DEFAULT_CONFIG
  };

  if (auth) {
    const user = isUserAuthenticated(true);
    const tokenurl = getTokenUrl();
    if (tokenurl) {
      CONFIG.headers = {
        Authorization: tokenurl
      };
    } else if (user?.isAutenticated) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else if (!user?.isAutenticated && user?.data?.token) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else {
      CONFIG.headers = {
      };
    }
  }

  if (third) {
    CONFIG.headers = {
      Authorization: third
    };
  }
  return axios.post(`${dev.URL_SERVER}${url}`, form, CONFIG);
};

export const putRequest = (url: string, body: any, auth = true, third = null): Promise<any> => {
  const CHAIN = JSON.stringify(body);
  const DATA = `json=${CHAIN}`;
  const CONFIG: AxiosRequestConfig = {
    ...AXIOS_DEFAULT_CONFIG,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  }
  if (auth) {
    const user = isUserAuthenticated(true);
    const tokenurl = getTokenUrl();
    if (tokenurl) {
      CONFIG.headers = {
        Authorization: tokenurl
      };
    } else if (user?.isAutenticated) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else if (!user?.isAutenticated && user?.data?.token) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else {
      CONFIG.headers = {
      };
    }
  }
  if (third) {
    CONFIG.headers = {
      Authorization: third
    };
  }
  return axios.put(`${dev.URL_SERVER}${url}`, DATA, CONFIG);
};

export const deleteRequest = (url: string, auth = true): Promise<any> => {
  const CONFIG: AxiosRequestConfig = {
    ...AXIOS_DEFAULT_CONFIG
  }
  if (auth) {
    const user = isUserAuthenticated(true);
    const tokenurl = getTokenUrl();
    if (tokenurl) {
      CONFIG.headers = {
        Authorization: tokenurl
      };
    } else if (user?.isAutenticated) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else if (!user?.isAutenticated && user?.data?.token) {
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else {
      CONFIG.headers = {
      };
    }
  }
  return axios.delete(`${dev.URL_SERVER}${url}`, CONFIG);
};

export const updateItem = async (
  url: string,
  data: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
  auth = true,
  third = null,
): Promise<boolean | any> => {
  let success = false;
  try {
    let resp: any = null;
    if (file) {
      resp = await postRequestForm(url, data, auth, third);
    } else {
      resp = (!data.id) ? await postRequest(url, data, auth, third)
        : await putRequest(url, data, auth, third);
    }

    if (resp.data.code === 200) {
      success = true;
      if (sendNotification) {
        notification(resp.data.message, mainTypeMsj.SUCCESS);
      }
    } else if (resp.data.codigoexcepcion === '500'){
      notification(resp.data.message, mainTypeMsj.ERROR);
    } else if (resp.data.code === 100) {
      notification(resp.data.message, mainTypeMsj.WARNING);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }

    if (requestData) {
      return resp?.data?.record || resp?.data?.records || resp?.data;
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

export const deleteItem = async (
  url: string,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  let success = false;
  try {
    const resp = await deleteRequest(url);
    if (resp.data.code === 200) {
      success = true;
      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
}

export const postRequestCustom = (url: string, body: any): Promise<any> => {
  const CONFIG: AxiosRequestConfig = {
    ...AXIOS_DEFAULT_CONFIG,
    headers: {
      'Content-Type': 'application/json'
    },
  }
  const tokenurl = getTokenUrl();
  if (tokenurl) {
    CONFIG.headers = {
      Authorization: tokenurl
    };
  } else if (isUserAuthenticated()) {
    const dataJson: IUserReducer = isUserAuthenticated(true);
    CONFIG.headers = {
      Authorization: dataJson.data?.token
    };
  } else {
    CONFIG.headers = {
    };
  }
  return axios.post(`${dev.URL_SERVER}${url}`, body, CONFIG);
};
