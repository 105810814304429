import { string } from 'yup';
import { IContractual } from '../../../core/models/contractual/contractualModel';
import { contractualTypeActions } from '../index';
import { IContractualActions, IContractualReducer } from '../interfaces/contractualInterfaces';

// Estado inicial
export const contractualInitialState: IContractualReducer = {
  id: null,
  proceso: null,
  tareas: [],
  documentos: [],
  contraUsuario: [],
  usuariosProceso: [],
  aprobaciones: [],
  roles: [],
  requerimientos: [],
};

/**
 * Reducer de proceso contractual
 * @param state estado del reducer
 * @param action acción a ejecutar
 */
const postcontractualReducer = (state = contractualInitialState, actions: IContractualActions): IContractualReducer => {
  switch (actions.type) {
    case contractualTypeActions.ID:
      return {
        ...state,
        id: actions.payload
      };

    case contractualTypeActions.PROCESO:
      return {
        ...state,
        proceso: (actions?.payload as IContractual)
      };

    case contractualTypeActions.POST_USER:
      return {
        ...state,
        contraUsuario: actions.payload
      };

    case contractualTypeActions.REQUERIMIENTOS:
      return {
        ...state,
        requerimientos: actions.payload
      };

    case contractualTypeActions.USUARIOS:
      return {
        ...state,
        usuariosProceso: actions.payload
      };

    case contractualTypeActions.TAREAS:
      return {
        ...state,
        tareas: actions.payload
      };

    case contractualTypeActions.DOCUMENTOS:
      return {
        ...state,
        documentos: actions.payload
      };

    case contractualTypeActions.APROBACIONES:
      return {
        ...state,
        aprobaciones: actions.payload
      };

    case contractualTypeActions.ROLES:
      return {
        ...state,
        roles: actions.payload
      };

    case contractualTypeActions.STATE:
      return actions.payload;

    default:
      return state;
  }
};
export default postcontractualReducer;
