import React, { FC } from "react";
import classNames from "classnames";
import { IHeaders } from "./Header";

const HeaderSubtitle: FC<IHeaders> = ({ children, className, tag: Tag, ...rest }) => (
  <Tag className={classNames("header-subtitle", className)} {...rest}>
    {children}
  </Tag>
);
HeaderSubtitle.defaultProps = {
  tag: "p"
};
export default HeaderSubtitle;
