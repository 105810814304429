import React, { FC } from "react";
import { Container, Row, Col } from "reactstrap";
import logoComforce from '../../assets/img/logos/logo.png';

const Footer: FC<any> = ({
  linkPage = 'https://comforce.co',
  namePage = 'comforce.co',
  label = 'Software de Contratos',
  linkTerm = 'https://comforce.co',
  labelTerm = 'Términos y condiciones.',
  logo
}) => (
  <footer className="footer mt-4 border-top">
    <Container fluid>
      <Row className="text-muted">
        <Col xs={12} className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <img
            src={logo || logoComforce}
            alt="Comforce"
            className="img-fluid"
            width="100"
          />
          <p className="mb-0 text-center">
            {label} <a href={linkPage} target="_blank" className="text-muted">{namePage}</a>
            &copy; {new Date().getFullYear()} -{" "}
            <a href={linkTerm} target="_blank" className="text-muted">{labelTerm}</a>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);
export default Footer;
