import { IUserActions, IUserReducer } from '../interfaces/userInterfaces';
import { userTypeActions } from '../index';
import { isUserAuthenticated } from '../../helpers/authUtils';

// Estado inicial
export const userInitialState = (): IUserReducer => {
  return isUserAuthenticated(true);
};

/**
 * Reducer de usuario
 * @param state estado del reducer
 * @param action acción a ejecutar
 */
const userReducer = (state = userInitialState(), actions: IUserActions): IUserReducer => {
  switch (actions.type) {
    case userTypeActions.LOGIN:
      return {
        ...state,
        data: actions.payload,
        isAutenticated: true,
      };

    case userTypeActions.LOGOUT:
      return {
        data: null,
        isAutenticated: false,
      };

    case userTypeActions.THIRD:
      return {
        data: actions.payload,
        isAutenticated: false,
      };

    default:
      return state;
  }
};
export default userReducer;
