import { userTypeActions } from '..';
import { IUserActions } from '../interfaces/userInterfaces';
import { IUsuarioAuth } from '../../../core/models/usuarios/authModel';

export function userLogin(payload: IUsuarioAuth): IUserActions {
  return {
    type: userTypeActions.LOGIN,
    payload
  };
}

export function userLogout(): IUserActions {
  return {
    type: userTypeActions.LOGOUT
  };
}

export function thirdToken(payload: IUsuarioAuth): IUserActions {
  return {
    type: userTypeActions.THIRD,
    payload
  };
}
