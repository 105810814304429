import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { changeHeader } from '../../../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../../../shared/contexts/interfaces/mainInterfaces';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import i18n from '../../../../i18n';
import { useLocation } from 'react-router-dom';
import { ITareas } from '../../../../core/models/procesos/tareasModel';
import TareasTableCliente from '../../../../shared/components/TareasTableCliente';

const TareasClientesView: FC = () => {
  // Context
  const { mainDispatch } = useMainContext();
  const { t } = useTranslation();
  const { language } = i18n;
  const { state } =  useLocation<ITareas[]>();

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: t('Radicación de solicitudes'),
        subtitle: [],
        type: mainTypeHeader.BREADCRUMB
      }
    }));
  }, [language])

  return (
    <Card style={{margin: 'auto', maxWidth: '100%', 'width': 500}}>
      <CardBody>
      <TareasTableCliente
        tareas={state}
        handleTarea={()=>{}}
        //disabled={disabled}
        />
      </CardBody>
    </Card>
  )
}

export default TareasClientesView;
