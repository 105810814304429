import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import {IContraUsuarios, IFormContraUsuarios} from "../../models/contractual/usuariosModel";

/**
 * Permite obtener los registros en la bd
 */
export const obtenerContractualUsuarios = async (filter?:string,valuefilter?:string): Promise<IContraUsuarios[]> => {
  let data: IContraUsuarios[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_CONTRACTUAL_USUARIOS, filter? {filter:filter,valuefilter:valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por criterio en la bd
 */
export const obtenerContraUsuarioByCriterio = async (id:number,criteria?:string,valuecriteria?:string): Promise<IContraUsuarios> => {
  let data: IContraUsuarios | any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_CONTRACTUAL_USUARIOS}${id}`,criteria? {criteria:criteria,valuecriteria:valuecriteria} : {});
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const guardarContractualUsuarios = async (
  values: IFormContraUsuarios,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_CONTRACTUAL_USUARIOS : `${urlApi.EDIT_CONTRACTUAL_USUARIOS}${values.id}`
  return await updateItem(url, values, notification, false, sendNotification);
};

/**
 * Permite borrar un registro
 * @param id
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const borrarContractualUsuarios = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_CONTRACTUAL_USUARIOS}${id}`;
  return await deleteItem(url, notification);
};
