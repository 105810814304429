import { MUIDataTableOptions } from 'mui-datatables';
import { IPaises } from '../core/models/administracion/paisesModel';

// Enums generales
export enum FormValidation {
  required = 'Este campo es requerido',
  email = 'Ingrese un correo electrónico valido',
  emailDup = 'Este correo electrónico ya esta en uso',
  nitDup = 'Este nit ya esta en uso',
  documentoDup = 'Este documento ya esta en uso',
  minLength = 'Minimo de caracteres permitidos',
  passwordMatch = 'Las contraseñas deben coincidir',
  passwordValid = 'La contraseña debe tener minimo 10 caracteres, máximo 19 caracteres, Al menos 1 mayúscula y 1 carácter especial',
  number = 'Este campo debe contener solo números',
  campoRepetido = 'Este campo esta repetido',
  maximumFiles = 'Ha cargado más archivos de los permitidos',
  maxPorcentaje = "El porcentaje maximo para este valor es de 100",
  maxLimite = "El valor máximo para este campo es de 100",
  cancelarSolicitud = "Es necesario que indique si cancela o no la solicitud",
  disabledValues = 'No se permite la selección de elementos inactivos',
};

export const EModulos = {
  CONTRACTUAL: {
    id: 1,
    nombre: 'contractual'
  },
  POSTCONTRACTUAL: {
    id: 2,
    nombre: 'postcontractual'
  },
  ASESORIAS: {
    id: 10,
    nombre: 'asesorias'
  },
};

export const EPostModules = {
  POSTCONTRACTUAL: {
    id: 1,
    nombre: "MOD_POST_POSTCONTRACTUAL"
  },
  OTROSI: {
    id: 2,
    nombre: "MOD_POST_OTROSI"
  },
  SUSPENSION: {
    id: 3,
    nombre: "MOD_POST_SUSPENSION"
  },
  LIQUIDACION: {
    id: 4, nombre: "MOD_POST_LIQUIDACION"
  },
  TAREAS: {
    id: 5,
    nombre: "MOD_TAREA_TAREAS"
  }
}

export enum ETipoPostProceso {
  OTROSI = 1,
  LIQUIDACION = 2,
  SUSPENSON = 3,
  RE_INICIO = 4
}

export enum EGroupsNames {
  EXPERIENCIA_CLIENTE_ESTRUCTURACION = 'ROLE_EXPERIENCIA_CLIENTE_ESTRUCTURACION', /**solicitante */
  EXPERIENCIA_CLIENTE_ASEGURAMIENTO_y_CONTROL = "ROLE_EXPERIENCIA_CLIENTE_ASEGURAMIENTO_Y_CONTROL",/**Aprobador */
  EXPERIENCIA_CLIENTE_GESTION = "ROLE_EXPERIENCIA_CLIENTE_GESTION",
  CUMPLIMIENTO_ESTRUCTURACION = "ROLE_CUMPLIMIENTO_ESTRUCTURACION",/**Aprobador */
  CUMPLIMIENTO_ASEGURAMIENTOY_CONTROL = "ROLE_CUMPLIMIENTO_ASEGURAMIENTO_Y_CONTROL",/**Aprobador */
  CUMPLIMIENTO_GESTION = "ROLE_CUMPLIMIENTO_GESTION",
  FORMALIZADOR_ASEGURAMIENTO_Y_CONTROL = "ROLE_FORMALIZADOR_ASEGURAMIENTO_Y_CONTROL",
  FORMALIZADOR_GESTION_TAREAS = "ROLE_FORMALIZADOR_GESTION_TAREAS",
  ABOGADO_EXTERNO = "ROLE_ABOGADO_EXTERNO",
  ABOGADO = 'ROLE_ABOGADO',
  COORDINADOR = 'ROLE_COORDINADOR',
  AUDITOR = "ROLE_AUDITOR",
  SUPER_USUARIO = "ROLE_SUPER_USUARIO",
  FIRMANTE = 'ROLE_FIRMANTE',
  APROBADOR = 'ROLE_APROBADOR',
  REPORTES = 'ROLE_REPORTES',
  TERCERO = 'ROLE_TERCERO',
}

export enum EGroupsIDs {
  EXPERIENCIA_CLIENTE_ESTRUCTURACION = 1,
  EXPERIENCIA_CLIENTE_ASEGURAMIENTO_y_CONTROL = 2,
  CUMPLIMIENTO_ESTRUCTURACION = 3,
  CUMPLIMIENTO_ASEGURAMIENTO = 4,
  FORMALIZADOR_ASEGURAMIENTO = 5,
  FORMALIZADOR_GESTION_TAREAS = 6,
  ABOGADO_EXTERNO = 7,
  ABOGADO = 8,
  COORDINADOR = 9,
  AUDITOR = 10,
  SUPER_USUARIO = 11,
  EXPERIENCIA_CLIENTE_GESTION = 12,
  CUMPLIMIENTO_GESTION = 13
}
// export enum ERolsProceso {
// EXPERIENCIA_CLIENTE_ESTRUCTURACION = 1,
//   COORDINADOR = 2,
//   ABOGADO = 3,
//   APROBADOR = 4,
//   FIRMANTE = 5,
//   JEFEAREA = 6,
// }

export enum ERolsProceso {
  EXPERIENCIA_CLIENTE_ESTRUCTURACION = 1,
  EXPERIENCIA_CLIENTE_GESTION = 2,
  SOLICITANTE = 3,
  JEFE_AREA = 4,
  APROBADOR_AREAS = 5,
  ABOGADO = 6,
  EXPERIENCIA_CLIENTE_ASEGURAMIETO_Y_CONTROL = 7,
  FORMALIZADOR_ASEGURAMIENTO_Y_CONTROL = 8,
  CUMPLIMIENTO_ASEGURAMIENTO_Y_CONTROL = 9,
  CUMPLIMIENTO_ESTRUCTURACION = 10,
  ABOGADO_EXTERNO = 11,
  FORMALIZADOR_GESTION = 12,
  CUMPLIMIENTO_GESTION = 13
}

export enum ETipoGuardadoProceso {
  GUARDAR = "guardar",
  GUARDAR_Y_AVANZAR = "guardar_y_avanzar"
}

export enum ECodeFabrica {
  /**POST */
  CREACION_PROCESO = "CreacionProceso",
  ACTA_INICIO = "ActaInicioContrato",
  EJECUCION = "Ejecucion",
  REVISION_SOLICITUD_NEGOCIO="RevisionSolicitudNegocio",
  /**OTROSI */
  SOLICITUD_OTROSI = "SolicitudOtrosi",
  GENERACION_CARTA_OTROSI = "GeneracionCartaOtrosi",
  REVSION_OTROSI_ASEGURAMIENTO_Y_CONTROL = "RevisionAseguramientoControl",
  REVSION_OTROSI_CUMPLIMENTO_ESTRUCTURACION = "RevisionCumplimientoEstructuracion",
  CARGUE_OTROS_FIRMADO = "CargueOtrosiFirmado",
  /**SUSPENSION */
  SOLICITUD_SUSPENSION = "SolicitudSuspension",
  CANCELACION_SUSPENSION = "CancelacionSolicitudSuspension",
  APROBACION_SOLICITUD_SUSPENSION = "AprobacionSolicitudSuspension",
  SUSPENDDO = "Suspendido",
  RENICIO_CONTRATO = "ReinicioContrato",
  /**LQUIDACION */
  SOLICITUD_LIQUDACION = "SolicitudLiquidacion",
  CANCELACION_LQUIDACION = "CancelacionSolicitudLiquidacion",
  APROBACION_TERMINACION_ANTICIPADA = "AprobacionTerminacionAnticipada",
  GENERACION_CARTA_LIQUIDACION = "GeneracionCartaProcesoLiquidacion",
  REVISION_ASEGURAMIENTO_CONTROL = "RevisionAseguramientoControl",
  REVISION_PROVEEDOR = "RevisionProveedor",
  REVISION_ABOGADO_EXTERNO = "RevisionAbogadoExterno",
  REVISIION_TAREAS_ABIERTAS = "RevisionTareasAbiertas",
  LIQUIDADO = "Liquidado",
  /**TAREAS V1 */
  TR_PARAMETROS_INICIALES = "ParametrosIniciales",
  TR_VALIDACION_TAREA = "ValidacionTarea",
  TR_REVISION_OTRAS_AREAS = "RevisionAreas",
  TR_EN_PROCESO = "EnProceso",
  TR_CERRADA = "Cerrada",
  /**TAREAS V2 - PREDETERMINADAS */
  TR_PRE_CREACION_TAREA = "PreCreacionTarea",
  TR_PRE_VALIDACION_CLIENTE = "PreValidacionCliente",
  TR_PRE_VALIDACION_SOLICITUD = "PreValidacionSolicitud",
  TR_PRE_REVISION_AREAS = "PreRevisionAreas",
  TR_PRE_REVISION_FORMALIZADOR = "PreRevisionFormalizador",
  TR_PRE_APROBACION_CUMPLIMIENTO = "PreAprobacionCumplimiento",
  TR_PRE_EN_PROCESO = "PreEnProceso",
  TR_PRE_CERRADA = "PreCerrada",
  TR_PRE_CANCELADA = "PreCancelada",
  /**TAREAS V2 - CLIENTES */
  TR_CLI_RADICACION_SOLICITUD = "CliRadicacionSolicitud",
  TR_CLI_VALIDACION_SOLICITUD = "CliValidacionSolicitud",
  TR_CLI_REVISION_AREAS = "CliRevisionAreas",
  TR_CLI_EN_PROCESO = "CliEnProceso",
  TR_CLI_CERRADA = "CliCerrada",
  TR_CLI_PENDIENTE_VERIFICACION = "CliPendienteVerificacion",
  TR_CLI_REVISION_FORMALIZADOR = "CliRevisionFormalizador", // Se cambia nombre por Validación responsable
  TR_CLI_APROBACION_CUMPLIMIENTO = "CliAprobacionCumplimiento",
  TR_CLI_EJECUCION_TRANSACCIONAL = "CliEjecucionTransaccional",
}

export enum EIdsEstados {
  /**POST */
  ACTA_INICIO_CONTRATO = 1,
  CREACION_PROCESO = 27,
  EJECUCION = 2,
  OTROSI_SOLICITUD = 3,
  OTROSI_GENERACION_CARTA = 4,
  OTROSI_REVISION_ASEGURAMIENTO_CONTROL = 5,
  OTROSI_REVISION_CUMPLIMIENTO_ESTRUCTURACION = 6,
  OTROSI_CARGUE_FIRMADO = 7,
  LIQUIDACION_SOLICITUD = 8,
  LIQUIDACION_CANCELACION_SOLICITUD = 9,
  LIQUIDACION_APROBACION_TERMINACIO_ANTICIPADA = 10,
  LIQUIDACION_GENERACION_CARTA = 11,
  LIQUIDACION_REVISION_ASEGURAMIENTO_CONTROL = 12,
  LIQUIDACION_REVISION_PROVEEDOR = 13,
  LIQUIDACION_REVISION_APROB_CARTA_ABOGADO_EXTERNO = 14,
  LIQUIDACION_REVISION_TAREAS_ABIERTAS = 15,
  LIQUIDACION_LIQUIDADO = 16,
  SUSPENSION_SOLICITUD = 17,
  SUSPESION_CANCELACION_SOLICITUD = 18,
  SUSPENSION_APROBACION_SOLICITUD = 19,
  SUSPENSION_SUSPENDIDO = 20,
  SUSPESION_RE_INICIO_CONTRATO = 21,
  TAREAS_PARAMETROS_INICIALES = 22,
  TAREAS_VALIDACION = 23,
  TAREAS_REVISION_OTRAS_AREAS = 24,
  TAREAS_EN_PROCESO = 25,
  TAREAS_CERRADA = 26
}

export enum EIdsModulos {
  POSTCOTRACTUAL = 1,
  OTROSI = 2,
  LIQUIDACION = 4,
  SUSPENSION = 3,
  TAREAS_CLIENTE = 5
}

export enum EEstadosCargueMasivo {
  CORRECTO = 'Finalizado correctamente',
  ERRONEO = 'Finalizado con error'
}

// interfaces generales
export interface ILayout {
  className?: string;
  children: any;
};
export interface IFormComponent {
  data?: IPaises;
  onSubmit: any;
}
export interface IBorrarRegistro {
  show: boolean,
  id: number
};

// data
export const optionsDataTable = (t): MUIDataTableOptions => ({
  filter: true,
  viewColumns: true,
  filterType: "dropdown",
  responsive: "standard",
  selectableRows: "none",
  download: false,
  elevation: 0,
  sort: true,
  print: false,
  textLabels: {
    body: {
      noMatch: t("Lo sentimos, no se han encontrado datos."),
      toolTip: t("Organizar"),
    },
    pagination: {
      next: t("Página siguiente"),
      previous: t("Página anterior"),
      rowsPerPage: t("Columnas por página:"),
      displayRows: t("de"),
    },
    toolbar: {
      search: t("Buscar"),
      print: t("Imprimir"),
      viewColumns: t("Ver columnas"),
      filterTable: t("Filtrar tabla"),
    },
    filter: {
      all: t("Todos"),
      title: t("FILTROS"),
      reset: t("LIMPIAR"),
    },
    viewColumns: {
      title: t("Mostar columnas"),
      titleAria: t("Mostrar/Ocultar columnas de la tabla"),
    },
    selectedRows: {
      text: t("fila(s) seleccionadas"),
      delete: t("Borrar"),
      deleteAria: t("Borrar filas seleccionadas"),
    },
  }
});


export const optionData = (lang, t) => {
  return {
    ...optionsDataTable(t),
    customSearch: (searchQuery, currentRow) => {
      const rexHab = lang === 'es' ? /^(h)(a|$)(b|$)(i|$)(l|$)(i|$)(t|$)(a|$)(d|$)(o|$)/ : /^(e)(n|$)(a|$)(b|$)(l|$)(e|$)(d|$)/;
      const rexDes = lang === 'es' ? /^(d)(e|$)(s|$)(h|$)(a|$)(b|$)(i|$)(l|$)(i|$)(t|$)(a|$)(d|$)(o|$)/ : /^(d)(i|$)(s|$)(a|$)(b|$)(l|$)(e|$)(d|$)/;
      let isFound = false;
      currentRow.forEach(col => {
        if (col?.toString()?.indexOf(searchQuery) >= 0) {
          isFound = true;
        }

        if (rexHab?.test(searchQuery?.toLowerCase()) && (currentRow[3] === 1)) {
          isFound = true;
        }

        if (rexDes?.test(searchQuery?.toLowerCase()) && (currentRow[3] !== 1)) {
          isFound = true;
        }

        if (currentRow[4]?.find(perfil => {
          return perfil?.nombre?.toLowerCase() == searchQuery?.toLowerCase();
        })) {
          isFound = true;
        }
      });
      return isFound;
    },
  }
};

export const optionEstadoSearch = (indexSearch: number, t): MUIDataTableOptions => {
  return {
    ...optionsDataTable(t),
    customSearch: (searchQuery, currentRow) => {
      const rexHab = /^(h)(a|$)(b|$)(i|$)(l|$)(i|$)(t|$)(a|$)(d|$)(o|$)/;
      const rexDes = /^(d)(e|$)(s|$)(h|$)(a|$)(b|$)(i|$)(l|$)(i|$)(t|$)(a|$)(d|$)(o|$)/;
      let isFound = false;
      currentRow.forEach(col => {
        if (col.toString().indexOf(searchQuery) >= 0) {
          isFound = true;
        }

        if (rexHab.test(searchQuery.toLowerCase()) && (currentRow[indexSearch] === 1)) {
          isFound = true;
        }

        if (rexDes.test(searchQuery.toLowerCase()) && (currentRow[indexSearch] !== 1)) {
          isFound = true;
        }
      });
      return isFound;
    },
  }
};

export enum ECatDocumentos {
  MINUTA = 'Minuta',
}

export enum ECategDocsTareas {
  DOCUMENTOS_ANEXOS = 1,
  ENTREGABLE_ACTIVIDAD = 2,
  SOLICITUD_ACREEDOR = 3,
  DOCUMENTO_FIRMADO = 4
}

export const getTokenUrl = () => {
  const data = window.location.pathname.match(/token\/([0-9]+)\/([a-zA-Z0-9-_.]+)/);
  if (data && data[2]) return data[2];
  else return null;
}
