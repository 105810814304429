import React, { FC, useMemo } from 'react';
import { ITareas } from '../../../core/models/procesos/tareasModel';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { useOptionsTables } from '../../../shared/hooks/useOptionsTables';
import { IPostcontractual } from '../../../core/models/postcontractual/postcontractualModel';
import { useAllListas } from '../../../shared/hooks/useAllListas';
import { useUserContext } from '../../../shared/contexts/userContext';
import { EGroupsNames } from '../../../shared/utilities';
import {formatStringtoDateString} from "../../../shared/helpers/Fecha";

const TareasPredeterminadas: FC<ITareasPredeterminadas> = ({ tareas, onChangePage, onSearchChange, total }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { optionsDataTable } = useOptionsTables();
  const { obtenerLabel } = useAllListas();
  const { userState } = useUserContext();

  const handleEdit = (id) => {
    history.push(`/postcontractual/edit/${id}`);
  }

  const esCoordinador = useMemo(() => (
    !!userState?.data?.roles?.find(rol => rol === EGroupsNames.COORDINADOR)
  ), [userState]);

  const esAdmin = useMemo(() => (
    !!userState?.data?.roles?.find(rol => rol === EGroupsNames.SUPER_USUARIO)
  ), [userState]);

  const esFormalizador = useMemo(() => (
    !!userState?.data?.roles?.find(rol => rol === EGroupsNames.FORMALIZADOR_ASEGURAMIENTO_Y_CONTROL)
  ), [userState]);

  const esCumplimiento = useMemo(() => (
    !!userState?.data?.roles?.find(rol => rol === EGroupsNames.CUMPLIMIENTO_ASEGURAMIENTOY_CONTROL)
  ), [userState]);

  const columns: MUIDataTableColumn[] = [
    {
      name: t('ID Tarea'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('ID Tarea')}</h5>
        ),
      }
    },
    {
      name:'Fecha de inicio',
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Fecha de inicio')}</h5>
        ),
      }
    },
    {
      name: t('Código del contrato'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Código del contrato')}</h5>
        ),
      }
    },
    {
      name: t('Nombre de la tarea'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Nombre de la tarea')}</h5>
        ),
      }
    },
    {
      name: t('Clasificación operativa'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Clasificación operativa')}</h5>
        ),
      }
    },
    {
      name: t('Clasificación según nivel de riesgo'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Clasificación según nivel de riesgo')}</h5>
        ),
      }
    },
    {
      name: t('Estado cumplimiento'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Estado cumplimiento')}</h5>
        ),
      }
    },
    {
      name: t('Responsable'),
      options: {
        filter: true,
        sort: true,
        display: esCoordinador || esAdmin,
        customHeadLabelRender: () => (
          <h5>{t('Responsable')}</h5>
        ),
      }
    },
    {
      name: t('Link de acceso'),
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Acciones')}</h5>
        ),
        customBodyRender: (id) => {
          return (
            <>
              <Button
                color="primary"
                className="mr-1 mb-1 btn-sm"
                onClick={() => handleEdit(id)}
              >
                <i className="uil-eye"></i>
              </Button>
            </>
          );
        }
      }
    },
  ];

  return (
    <>
      <MUIDataTable
        title={(<h5>{t('Gestión Pendientes Obligaciones Contractuales')}</h5>)}
        data={tareas.filter(item => (esCumplimiento || esFormalizador) || item.usuarioasignado?.id === userState?.data?.sub).map(record => ([
          record.id,
          formatStringtoDateString(record?.inicio),
          (record.idpostcontractual as IPostcontractual)?.codigocontrato,
          obtenerLabel(record.nombre || ''),
          obtenerLabel(record.clasificacionoperativa || ''),
          obtenerLabel(record.clasificacionnivelriesgo || ''),
          record.estado,
          record.usuariopendienteacccion,
          (record.idpostcontractual as IPostcontractual)?.id,
        ]))}
        columns={columns}
        options={optionsDataTable({
          sortOrder: {
            name: 'Fecha de inicio',
            direction: 'desc',
          },
          sort: true,
          filter: false,
          search: true,
          serverSide: true,
          onChangePage,
          onSearchChange,
          count: total,
          rowsPerPage: 10,
          rowsPerPageOptions: [],
        })}
      />
    </>
  )
}

interface ITareasPredeterminadas {
  tareas: ITareas[];
  onChangePage: (page: number) => void;
  onSearchChange: (searchText: string) => void;
  total: number;
}

export default TareasPredeterminadas;
