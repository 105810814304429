import React, { FC, useContext } from 'react';
// Reactstrap
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
//FORM
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormValidation } from '../utilities';
import ButtonCustom from './ButtonCustom';
import { mainContext } from '../contexts';
import { closeModal } from '../contexts/actions/mainActions';
import { useTranslation } from 'react-i18next';

const RecuperarContrasena: FC<any> = ({ onSubmit }) => {
  const { mainState, mainDispatch } = useContext(mainContext);
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(FormValidation.email).required(FormValidation.required)
      })}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => {
        return (
          <>
            <ModalBody>
              <Form id="formRecuperacion">
                <Row>
                  <Col sm={12}>
                    <FormGroup>
                      <Label>{t('Correo')}</Label>
                      <Field
                        as={Input}
                        name="email"
                        placeholder={t('Ingrese el correo de recuperación')}
                        invalid={errors.email && touched.email}
                      />
                      <FormFeedback>{errors.email && t(errors.email)}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <ButtonCustom
                form="formRecuperacion"
                className="d-flex align-items-center"
                type="submit"
                color="primary"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {t('Enviar')}
              </ButtonCustom>
              <ButtonCustom
                className="d-flex align-items-center"
                color="danger"
                disabled={mainState.loading}
                onClick={() => mainDispatch(closeModal())}
              >
                {t('Cerrar')}
              </ButtonCustom>
            </ModalFooter>
          </>
        )
      }}
    </Formik>
  );
};
export default RecuperarContrasena;
