import axios from 'axios';

const axiosInt = axios.create();

axiosInt.interceptors.response.use(
  (res: any) => {
    if (res?.code === 403) {
      return Promise.reject(res);
    }
    return res;
  },
  err => {
    return Promise.reject(err);
  }
);
export default axiosInt;
