import { Dispatch } from 'react';
import { IUsuarioAuth } from '../../../core/models/usuarios/authModel';

export interface IUserReducer {
  isAutenticated: boolean;
  data?: IUsuarioAuth | null;
};
export interface IUserActions {
  type: string,
  payload?: IUsuarioAuth | any
};
export interface IUserContext {
  userState: IUserReducer,
  userDispatch: Dispatch<IUserActions>,
};

export enum Emsg {
  expired= 'Sesión expirada'
};
