import { IMainReducer, IMainActions, mainTypeHeader } from '../interfaces/mainInterfaces';
import { mainTypeActions } from '..';

export const mainInitialState = (): IMainReducer => {
  return {
    header: {
      type: mainTypeHeader.SUBTITLE,
      title: '',
      subtitle: ''
    },
    modal: {
      show: false,
      title: '',
      body: null
    },
    loading: false,
    language: 'es',
  };
};

/**
 * Reducer del sistema para cambiar estado globales del mismo
 * @param state estado del reducer
 * @param action acción a ejecutar
 */
const mainReducer = (state = mainInitialState(), actions: IMainActions): IMainReducer => {
  switch (actions.type) {
    case mainTypeActions.HEADER:
      return {
        ...state,
        header: actions.payload?.header || state.header
      };

    case mainTypeActions.OPEN_MODAL:
      return {
        ...state,
        modal: actions.payload?.modal || state.modal
      };

    case mainTypeActions.CHANGE_LOADING:
      return {
        ...state,
        loading: !state.loading
      };

    case mainTypeActions.TRUE_LOADING:
      return {
        ...state,
        loading: true
      };

    case mainTypeActions.FALSE_LOADING:
      return {
        ...state,
        loading: false
      };

    case mainTypeActions.CLOSE_MODAL:
      return {
        ...state,
        loading: false,
        modal: {
          show: false,
          title: '',
          body: null,
        }
      };

    case mainTypeActions.CHANGE_LANGUAGE:
      return {
        ...state,
        language: actions.payload?.language
      };

    default:
      return state;
  }
};
export default mainReducer;
