import { string } from 'yup';
import { IPostcontractual } from '../../../core/models/postcontractual/postcontractualModel';
import { postcontractualTypeActions } from '../index';
import { IPostcontractualActions, IPostcontractualReducer } from '../interfaces/postcontractualInterface';

// Estado inicial
export const postcontractualInitialState: IPostcontractualReducer = {
  id: null,
  proceso: null,
  tareas: [],
  documentos: [],
  postUsuario: [],
  usuariosProceso: [],
  aprobaciones: [],
  roles: [],
  requerimientos: [],
};

/**
 * Reducer de proceso postcontractual
 * @param state estado del reducer
 * @param action acción a ejecutar
 */
const postcontractualReducer = (state = postcontractualInitialState, actions: IPostcontractualActions): IPostcontractualReducer => {
  switch (actions.type) {
    case postcontractualTypeActions.ID:
      return {
        ...state,
        id: actions.payload
      };

    case postcontractualTypeActions.PROCESO:
      return {
        ...state,
        proceso: (actions?.payload as IPostcontractual)
      };

    case postcontractualTypeActions.POST_USER:
      return {
        ...state,
        postUsuario: actions.payload
      };

    case postcontractualTypeActions.REQUERIMIENTOS:
      return {
        ...state,
        requerimientos: actions.payload
      };

    case postcontractualTypeActions.USUARIOS:
      return {
        ...state,
        usuariosProceso: actions.payload
      };

    case postcontractualTypeActions.TAREAS:
      return {
        ...state,
        tareas: actions.payload
      };

    case postcontractualTypeActions.DOCUMENTOS:
      return {
        ...state,
        documentos: actions.payload
      };

    case postcontractualTypeActions.APROBACIONES:
      return {
        ...state,
        aprobaciones: actions.payload
      };

    case postcontractualTypeActions.ROLES:
      return {
        ...state,
        roles: actions.payload
      };

    case postcontractualTypeActions.STATE:
      return actions.payload;

    default:
      return state;
  }
};
export default postcontractualReducer;
